<template>
	<div>
		<div class="page-title">
			<a>
				<router-link to="/store/role/index">
					<el-button size="mini" icon="el-icon-back" class="mr-3">返回</el-button>
				</router-link>
			</a>
			<span style="font-size: 20px;">添加角色</span>
		</div>
		<div class="bg-white p-3 m-2">
			<el-form ref="form"  :model="form" :rules="rules" label-width="100px" style="max-width: 750px;">
				<el-form-item label="角色名称"  prop="role_name">
					<el-input v-model="form.role_name" placeholder="名称"></el-input>
				</el-form-item>
				<el-form-item label="排序"  prop="sort">
					<el-input v-model="form.sort" placeholder="排序"></el-input>
				</el-form-item>
				<el-form-item label="分配权限" prop="access">
					<el-tree ref="tree" style="width: 100%;" :data="accessList" node-key="access_id"
						:props="defaultProps" @check="check" default-expand-all :expand-on-click-node="false"
						:check-strictly="true" show-checkbox></el-tree>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submit('form')">确 定</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['app', 'layout'],
		data() {
			return {
				accessList: [],
				defaultProps: {
					children: 'child',
					label: 'name'
				},
				form: {
					role_name: '',
					sort: 100,
					access: [],
				},
				rules: {
					role_name: [{
						required: true,
						message: '请输入角色名称',
						trigger: 'change'
					}, ],
					sort: [{
						required: true,
						message: '请输入排序',
						trigger: 'change'
					}, ],
					access: [{
						required: true,
						message: '请分配权限',
						trigger: 'change'
					}, ],
				},
			}
		},
		created() {
			this.getAccesslist()
		},
		methods: {
			check(...e) {
				this.form.access = e[1].checkedKeys
			},
			getAccesslist() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/store.access/index',
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.accessList = res.data.data.tree
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 提交
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.layout.showLoading()
						this.axios({
							token: true,
							params: {
								s: 'store/store.role/add',
							},
							data: {
								role: this.form
							},
							method: 'post',
						}).then(res => {
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								// 跳转后台首页
								this.$router.push({
									name: 'store.role/index'
								})
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
							this.layout.hideLoading()
						}).catch(err => {
							this.layout.hideLoading()
						})
					}
				})
			},
		},
	}
</script>

<style>

</style>
